.contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  box-sizing: border-box;
  padding: 60px 50px;
  width: 100%;
  height: fit-content;
  background: #efefef;
}

.con1 {
  width: 55%;
  height: 350px;
}

.img {
  width: 40%;
  height: 370px;
  border-radius: 10px;
  background-image: url("https://images.unsplash.com/photo-1653289755854-a41949e96282?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.con1 > form {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  gap: 20px;
}

.form1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.form1 > * {
  width: 48%;
  height: 40px;
  background-color: transparent;
  border: 1.5px solid #080047;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0px 15px;
  color: #080047;
  font-family: "Poppins", sans-serif;
}

.form1 > *::placeholder {
  color: #080047;
}

.input {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: 1.5px solid #080047;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0px 15px;
  color: #080047;
  font-family: "Poppins", sans-serif;
}

.con1 > form > textarea {
  width: 100%;
  min-height: 180px;
  background-color: transparent;
  border: 1.5px solid #080047;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px 15px;
  color: #080047;
  font-family: "Poppins", sans-serif;
}

.con1 > form > button {
  width: 100%;
  height: 40px;
  background-color: #080047;
  color: #fff;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px 15px;
  font-family: "Poppins", sans-serif;
  outline: none;
  border: none;
  transition: 0.4s ease;
}

.con1 > form > button:hover {
  background-color: #0000D4;
}

.contact > img {
  border-radius: 20px;
}


@media screen and (max-width: 1000px) {
  .contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0px 10px;
    padding-top: 60px;
    padding-bottom: 20px;
    width: 100%;
    height: fit-content;
    background: #efefef;
    gap: 50px;
  }
  
  .con1 {
    width: 100%;
    height: 350px;
  }
  
  .img {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    background-image: url("https://images.unsplash.com/photo-1653289755854-a41949e96282?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .con1 > form {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    gap: 20px;
  }
  
  .form1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  
  .form1 > * {
    width: 48%;
    height: 40px;
    background-color: transparent;
    border: 1.5px solid #080047;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 0px 15px;
    color: #080047;
    font-family: "Poppins", sans-serif;
  }
  
  .form1 > *::placeholder {
    color: #080047;
  }
  
  .input {
    width: 100%;
    height: 40px;
    background-color: transparent;
    border: 1.5px solid #080047;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 0px 15px;
    color: #080047;
    font-family: "Poppins", sans-serif;
  }
  
  .con1 > form > textarea {
    width: 100%;
    min-height: 180px;
    background-color: transparent;
    border: 1.5px solid #080047;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 10px 15px;
    color: #080047;
    font-family: "Poppins", sans-serif;
  }
  
  .con1 > form > button {
    width: 100%;
    height: 40px;
    background-color: #080047;
    color: #fff;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 10px 15px;
    font-family: "Poppins", sans-serif;
    outline: none;
    border: none;
    transition: 0.4s ease;
  }
  
  .con1 > form > button:hover {
    background-color: #0000D4;
  }
  
  .contact > img {
    border-radius: 20px;
  } 
}