.services {
    background-image: linear-gradient(to bottom  , rgba(0, 0, 0, 0.233), rgba(0, 3, 62, 0.831)), url('../assets/images/Dubai.jpg');
    background-size: cover; /* Adjust as needed */
    background-position: center; /* Adjust as needed */
    background-repeat: no-repeat; /* Adjust as needed */
    width: 100vw;
    height: fit-content;
    box-sizing: border-box;
    padding: 60px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 60px;
}

.services > h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 22px;
    color: #fff;
}

.serviceList{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, fit-content); 
    gap: 40px;
    align-items: start;
    justify-items: center;
    width: fit-content;
    height: fit-content;
    
}

.service{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    font-family: "Poppins";
    gap: 10px;
    box-sizing: border-box;
    padding: 10px 10px;
}

.service h3 {
    font-size: 20px;
    color: white;
    margin: 0;
    font-weight: 500;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
}

.service > h3::after{
    content: "";
    border: 1px solid #0000D4;
    width: 70%;
}

.service > p {
    color: white;
    font-size: 14px;
    margin: 0;
    font-weight: 400;
}

@media screen and (max-width: 1000px) {
    .services {
        background-image: linear-gradient(to bottom  , rgba(0, 0, 0, 0.233), rgba(0, 3, 62, 0.831)), url('../assets/images/Dubai.jpg');
        background-size: cover; /* Adjust as needed */
        background-position: center; /* Adjust as needed */
        background-repeat: no-repeat; /* Adjust as needed */
        width: 100vw;
        height: fit-content;
        box-sizing: border-box;
        padding: 60px 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 60px;
    }
    
    .services > h1 {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 22px;
        color: #fff;
    }
    
    .serviceList{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(2, fit-content); 
        gap: 40px;
        align-items: start;
        justify-items: center;
        width: fit-content;
        height: fit-content;
        
    }
    
    .service{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        font-family: "Poppins";
        gap: 10px;
        box-sizing: border-box;
        padding: 10px 10px;
    }
    
    .service h3 {
        font-size: 20px;
        color: white;
        margin: 0;
        font-weight: 500;
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .service > h3::after{
        content: "";
        border: 1px solid #0000D4;
        width: 70%;
    }
    
    .service > p {
        color: white;
        font-size: 14px;
        margin: 0;
        text-align:center;
        font-weight: 400;
    }
}