.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 30px 70px;
    flex-direction: column;
    gap: 40px;
    background-color: #fff;
    width: 100%;
    height: fit-content;
}

.f1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    flex-direction: row;
    width: 100%;
    height: fit-content;
}

.ft2{
    display: flex;
    align-items: end;
    justify-content: space-between;
    box-sizing: border-box;
    flex-direction: column;
    gap: 10px;
}

.f2 > p{
    text-align: center;
    font-size: 13px;
    color: #b7b4b4;
    margin: 0;
}

.option{
    text-decoration: none;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #080047;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    transition: 0.3s ease;
    border: none;
    outline: none;
}

.option:hover{
    color: #0000D4;
    transition: 0.3s ease;
}

.option::after{
    content: " ";
    border: 0px solid #0000D4;
    width: 0%;
    position: absolute;
    margin-top: 20px;
    transition: 0.3s ease;
}

.option:hover::after{
    content: " ";
    border: 1px solid #0000D4;
    width: 100%;
    position: absolute;
    margin-top: 20px;
    animation: after_ani 0.3s ease ;
}

@media screen and (max-width: 1000px) {
    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 30px 70px;
        flex-direction: column;
        gap: 40px;
        background-color: #fff;
        width: 100%;
        height: fit-content;
    }
    
    .f1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        flex-direction: column;
        width: 100%;
        height: fit-content;
    }
    
    .ft2{
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        flex-direction: column;
        gap: 10px;
    }
    
    .f2 > p{
        text-align: center;
        font-size: 13px;
        color: #b7b4b4;
        margin: 0;
    }
    
    .option{
        text-decoration: none;
        font-family: "Lato", sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: #080047;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        position: relative;
        transition: 0.3s ease;
        border: none;
        outline: none;
    }
    
    .option:hover{
        color: #0000D4;
        transition: 0.3s ease;
    }
    
    .option::after{
        content: " ";
        border: 0px solid #0000D4;
        width: 0%;
        position: absolute;
        margin-top: 20px;
        transition: 0.3s ease;
    }
    
    .option:hover::after{
        content: " ";
        border: 1px solid #0000D4;
        width: 100%;
        position: absolute;
        margin-top: 20px;
        animation: after_ani 0.3s ease ;
    }
}