.company{
    width: 100vw;
    height: fit-content;
    background-color: #fff;
    color: #080047;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 50px;
    padding-left: 200px;
    padding-right: 200px;
    gap:20px;
}

.company > p {
    text-align: start;
    font-size: 18px;
}

@media screen and (max-width: 1300px) {

    .company{
        padding-left: 100px;
        padding-right: 100px;
    }
}

@media screen and (max-width: 1000px) {

    .company{
        padding-left: 40px;
        padding-right: 40px;
    }

    .company > p {
        text-align: justify;
        font-size: 18px;
    }
}