.home {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;
}

video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -10;
}

.headcut {
  margin-top: 80px;
  width: 100%;
  height: 100%;
  display: flex;
}

.textcut {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: flex-start;
}

.textcut > h1 {
  display: none;
}

.textcut > svg {
  width: 100%;
  height: fit-content;
}

@media screen and (max-width: 1000px) {
  .home {
    height: 90vh;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .headcut {
    margin-top: 80px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .textcut {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 25px;
    box-sizing: border-box;
    color: #fff;
    text-align: start;
    font-family: "Poppins", sans-serif;
  }

  .textcut h1 {
    display: flex;
    flex-direction: column;
    font-weight: 500;
  }

  .textcut h1 > span {
    font-size: 80px;
    font-weight: 700;
  }

  .textcut > svg {
    display: none;
  }
}
