.projects {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  background-color: #fff;
  padding: 70px 0px;
  box-sizing: border-box;
}

.card {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  width: 25%;
  height: 360px;
  gap: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px #bbbbbb;
}

#card1 {
  background-image: url("https://images.unsplash.com/photo-1507608616759-54f48f0af0ee?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#card2 {
    background-image: url("https://images.unsplash.com/photo-1621264448270-9ef00e88a935?q=80&w=1914&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #card3 {
    background-image: url("https://images.unsplash.com/photo-1623039405147-547794f92e9e?q=80&w=2026&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

.cardcon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 0px;
  padding-bottom: 30px;
  background: linear-gradient(
    to top,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: 2s ease;
}

.cardinfo {
  display: flex;
  align-items: start;
  justify-content: end;
  flex-direction: column;
  height: 70%;
  width: calc(100% - 40px);
  gap: 10px;
}

.cardinfo > * {
  margin: 0;
  color: #080047;
}

.cardinfo > h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 24px;
}

.cardinfo > p {
  font-family: "Lato", sans-serif;
  font-size: 17px;
  font-weight: 600;
}

.cardbtn {
  width: calc(100% - 30px);
}

.cardbtn > button {
  width: 100%;
  background-color: #080047;
  color: #fff;
  height: 40px;
  font-family: "Poppins", sans-serif;
  transition: 0.4s ease;
  outline: none;
  border: none;
  border-radius: 5px;
}

.cardbtn > button:hover {
  background-color: #0000D4;
}

.card:hover > .cardcon {
  transition: 1s ease;
  height: 85%;
  background-color: #fff;
  gap: 40px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.card:hover .cardinfo {
  height: 80%;
  transition: 1s ease;
}
.card:hover .cardinfo > p {
  transition: 1s ease;
  font-weight: 400;
}


@media screen and (max-width: 1000px) {
  .projects {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background-color: #fff;
    padding: 50px 15px;
    gap: 30px;
    box-sizing: border-box;
  }
  
  .card {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 360px;
    gap: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px #bbbbbb;
  }
  
  #card1 {
    background-image: url("https://images.unsplash.com/photo-1507608616759-54f48f0af0ee?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  #card2 {
      background-image: url("https://images.unsplash.com/photo-1621264448270-9ef00e88a935?q=80&w=1914&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  
    #card3 {
      background-image: url("https://images.unsplash.com/photo-1623039405147-547794f92e9e?q=80&w=2026&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  
  .cardcon {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    padding: 10px 0px;
    padding-bottom: 30px;
    background: linear-gradient(
      to top,
      rgb(255, 255, 255) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: 2s ease;
  }
  
  .cardinfo {
    display: flex;
    align-items: start;
    justify-content: end;
    flex-direction: column;
    height: 70%;
    width: calc(100% - 40px);
    gap: 10px;
  }
  
  .cardinfo > * {
    margin: 0;
    color: #080047;
  }
  
  .cardinfo > h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 24px;
  }
  
  .cardinfo > p {
    font-family: "Lato", sans-serif;
    font-size: 17px;
    font-weight: 600;
  }
  
  .cardbtn {
    width: calc(100% - 30px);
  }
  
  .cardbtn > button {
    width: 100%;
    background-color: #080047;
    color: #fff;
    height: 40px;
    font-family: "Poppins", sans-serif;
    transition: 0.4s ease;
    outline: none;
    border: none;
    border-radius: 5px;
  }
  
  .cardbtn > button:hover {
    background-color: #0000D4;
  }
  
  .card:hover > .cardcon {
    transition: 1s ease;
    height: 85%;
    background-color: #fff;
    gap: 40px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  
  .card:hover .cardinfo {
    height: 80%;
    transition: 1s ease;
  }
  .card:hover .cardinfo > p {
    transition: 1s ease;
    font-weight: 400;
  }
  
}