.nav{
    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 100vw;
    box-sizing: border-box;
    padding: 0px 100px;
    height: 80px;
    background-color: #fff;
    position: fixed;
}

.logo, .end{
    width: 20%;
    display: flex;
    align-items: center;
}

.logo{
    justify-content: flex-start;
}

.end{
    justify-content: flex-end;
}

.navopt{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.option{
    text-decoration: none;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #080047;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    transition: 0.3s ease;
    border: none;
    outline: none;
}

.option:hover{
    color: #0000D4;
    transition: 0.3s ease;
}

.option::after{
    content: " ";
    border: 0px solid #0000D4;
    width: 0%;
    position: absolute;
    margin-top: 20px;
    transition: 0.3s ease;
}

.option:hover::after{
    content: " ";
    border: 1px solid #0000D4;
    width: 100%;
    position: absolute;
    margin-top: 20px;
    animation: after_ani 0.3s ease ;
}

.end>button{
    border: none;
    outline: none;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 15px;
    padding: 12px 20px;
    background-color: #080047;
    color: #fff;
    border-radius: 5px;
    transition: 0.3s ease;
}

.end>button:hover{
    background-color: #6f68a0;
}

@keyframes after_ani {
    0%{
        width: 0px;
    }
    100%{
        width: 100%;
    }
}

@media screen and (max-width: 1000px) {
    .nav{
        display: flex;
        align-content: center;
        justify-content: center;
    }
    
    .logo{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding-left: 21px;
    }

    
    .navopt{
        display: none;
    }
    
    .end>button{
        display: none;
    }
}