.about {
  width: 100vw;
  height: fit-content;
  background-color: white;
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: row;
  box-sizing: border-box;
  padding: 70px 200px;
}

.abt {
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  width: 25%;
  gap: 20px;
}

.pic > img {
  border-radius: 1000px;
  width: 100px;
  height: 100px;
}

.abtt {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.abtt h3 {
  margin: 0;
  line-height: 1;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  color: #1400af;
}

.abtt p {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  color: #005aafc9;
  font-size: 15px;
}

.abt > p {
  text-align: justify;
  width: 100%;
  overflow-wrap: break-word;
  margin: 0;
  color: rgb(139, 139, 139);
}

.divider {
  border: 1px solid rgb(236, 236, 236);
  align-self: center;
  height: 40vh;
}

@media screen and (max-width: 1300px) {
  .about {
    padding: 40px 100px;
    flex-direction: column;
    gap: 20px;
  }
  .abt {
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }

  .divider {
    border: 1px solid rgb(236, 236, 236);
    align-self: center;
    width: 100%;
    height: 0;
  }
}

@media screen and (max-width: 1000px) {
  .about {
    padding: 40px 30px;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
  }
  .abt {
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }

  .divider {
    border: 1px solid rgb(236, 236, 236);
    align-self: center;
    width: 100%;
    height: 0;
  }
}